export default [
  {
    name: 'externo-login',
    path: '/externo/login',
    component: () => import('@/app/externo/login/LoginExterno.vue'),
    meta: {
      resource: 'dashboardexterno',
      action: 'eden',
      requiredAuth: false,
      layout: 'full',
    },
  },
]
