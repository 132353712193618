export default [
  {
    name: 'confirmar-acesso-externo',
    path: '/externo/confirmar-acesso',
    component: () => import('@/app/externo/confirmar-acesso-externo/ConfirmarAcessoExterno.vue'),
    meta: {
      requiredAuth: false,
      layout: 'full',
    },
  },
]
