export default {
  // Endpoints
  loginEndpoint: '/seguranca/login',
  alterarSenhaEndpoint: '/seguranca/trocar-senha',
  forgotPasswordEndpoint: '/seguranca/esqueci-senha',
  confirmAccessEndpoint: '/seguranca/confirmar-acesso',
  registerEndpoint: '',
  refreshEndpoint: '',
  logoutEndpoint: '',

  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: '',
}
