export default [{
  path: '/cliente-externo',
  name: 'clienteExterno',
  component: () => import('@/app/externo/cliente/ClienteExterno.vue'),
  meta: {
    resource: 'dashboardexterno',
    action: 'update',
    pageTitle: 'Cliente',
    breadcrumb: [],
  },
}]
